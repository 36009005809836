export const BASE_URL = process.env.BASE_URL
// 头像上传占位图
export const PROFILE_UPLOAD_HOLDER = `${BASE_URL}images/common/profile-holder.png`
// 身份证正面上传占位图
export const ID_CARD_UPLOAD_HOLDER = `${BASE_URL}images/company/id-card.png`
// 身份证反面上传占位图
export const ID_CARD_BACK_UPLOAD_HOLDER = `${BASE_URL}images/company/id-card-back.png`
// 营业执照占位图
export const LICENSE_UPLOAD_HOLDER = `${BASE_URL}images/company/license.png`
// 身份证正面示例
export const ID_CARD_FRONT = `${BASE_URL}images/company/id-card-front.png`
// 身份证反面示例
export const ID_CARD_NEGATIVE = `${BASE_URL}images/company/id-card-negative.png`
// 营业执照示例
export const BUSINESS_LICENSE = `${BASE_URL}images/company/business-license.png`
// 首页
export const ICON_HOME_PROMISS = `${BASE_URL}images/common/home-icon.png`
// 通讯录权限icon
export const ICON_ADDRESS_PROMISS = `${BASE_URL}images/common/address-icon.png`
// 我的企业权限icon
export const ICON_COMPANY_PROMISS = `${BASE_URL}images/common/company-icon.png`
// 设置权限icon
export const ICON_SETTING_PROMISS = `${BASE_URL}images/common/setting-icon.png`
// 文件夹
export const ICON_FOLDER = `${BASE_URL}images/common/folder.png`
// 人员
export const ICON_PERSONNEL = `${BASE_URL}images/common/personnel.png`

export const XLS_ICON = `${BASE_URL}images/common/xls_icon.png`

/**
 * 管理范围 字典
 */
export const CONTROL_SCOPE_DICT = {
  ALL: '1', // 全公司
  DEPARTMENT_ALL: '2', // 部门及以下数据
  DEPARTMENT: '3', // 部门
  1: '全公司',
  2: '所在部门及下级部门',
  3: '特定部门'
}

export const INDUSTRY_DICT = {
  1: '物业行业',
  2: '地产行业',
  3: '其他'
}
export const SCALE_DICT = {
  1: '0-19人',
  2: '20-99人',
  3: '100-499人',
  4: '500-1000人',
  5: '1000人以上'
}
/**
 * 消息类型
 */
export const MESSAGE_TYPE = {
  1: '全部消息',
  2: '系统通知'
  // 3: '待办工单'
  // 4: '待批申请'
}

<template>
  <div class="company-info__item">
    <div class="item-label">
      <slot name="label"></slot>
    </div>
    <div class="item-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'company-info-item',
  setup() {
    return {}
  }
}
</script>

<template>
  <div class="company-info-page" v-loading="state.loadingState">
    <company-info-item class="logo">
      <template #label>企业logo</template>
      <template #default>
        <div class="logo-wrap">
          <img :src="logo" alt="" />
          <el-upload
            class="avatar-uploader"
            action="/"
            ref="uploadRef"
            :accept="settings.accept"
            :before-upload="beforeUpload"
            :http-request="httpRequest"
            :show-file-list="false"
          >
            <div class="logo-trigger">
              <i class="el-icon-upload"></i>
              <span>更换LOGO</span>
            </div>
          </el-upload>
        </div>
        <div class="logo-tip">
          <i class="r-color">*</i>
          推荐尺寸为702*180，大小不超过1M
          <i class="r-color">*</i>
        </div>
        <div class="btn" @click="onAuthClick" v-if="false">企业认证</div>
      </template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">企业名称</div>
      </template>
      <template #default>
        <div class="text">
          <span>{{ state.companyName }}</span>
          <span class="edit-text" @click="onEditClick('companyName')">修改</span>
        </div>
      </template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">企业简称</div>
      </template>
      <template #default>
        <div class="text">
          <span>{{ state.shortName }}</span>
          <span class="edit-text" @click="onEditClick('shortName')">修改</span>
        </div>
      </template>
    </company-info-item>
    <div class="company-info__line"></div>
    <company-info-item>
      <template #label>
        <div class="text">企业地址</div>
      </template>
      <template #default>
        <div class="text">
          <span>{{ state.address }}</span>
          <span class="edit-text" @click="onEditClick('address')">修改</span>
        </div>
      </template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">联系电话</div>
      </template>
      <template #default>
        <div class="text">{{ state.phone }}</div>
        <span class="edit-text" @click="onEditClick('phone')">修改</span>
      </template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">企业域名</div>
      </template>
      <template #default>
        <div class="text">{{ state.domain }}</div>
        <span class="edit-text" @click="onEditClick('domain')">修改</span>
      </template>
    </company-info-item>
    <div class="company-info__line"></div>
    <company-info-item>
      <template #label>
        <div class="text">企业成员</div>
      </template>
      <template #default>
        <div class="text">{{ state.employeeNumber }}个成员</div>
      </template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">企业部门</div>
      </template>
      <template #default>{{ state.departmentNumber }}个部门</template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">已使用/人数上限</div>
      </template>
      <template #default>
        <div class="text">{{ state.employeeNumber }}/2000</div>
        <!-- <div class="button mg-l">申请扩容</div> -->
      </template>
    </company-info-item>
    <div class="company-info__line"></div>
    <company-info-item>
      <template #label>
        <div class="text">发票抬头</div>
      </template>
      <template #default>
        <div class="button" @click="addInvoice">{{ state.invoiceInfo?.id ? '修改' : '添加发票' }}</div>
        <div class="desc">为企业成员配置增值发票抬头</div>
        <div class="invoice ellipsis" v-if="state.invoiceInfo?.id">{{ state.invoiceInfo?.companyName }}</div>
      </template>
    </company-info-item>
    <div class="company-info__line"></div>
    <company-info-item>
      <template #label>
        <div class="text">行业类型</div>
      </template>
      <template #default>
        <div class="text">{{ INDUSTRY_DICT[state.industry] || state.industry }}</div>
        <span class="edit-text" @click="onEditClick('industry')">修改</span>
      </template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">人员规模</div>
      </template>
      <template #default>
        <div class="text">{{ SCALE_DICT[state.scale] || state.scale }}</div>
        <span class="edit-text" @click="onEditClick('scale')">修改</span>
      </template>
    </company-info-item>
    <div class="company-info__line"></div>
    <company-info-item>
      <template #label>
        <div class="text">创建日期</div>
      </template>
      <template #default>{{ dateFormat(state.createTime) }}</template>
    </company-info-item>
    <company-info-item>
      <template #label>
        <div class="text">企业ID</div>
      </template>
      <template #default>{{ state.id }}</template>
    </company-info-item>
  </div>
  <el-dialog
    v-model="dialogState.showEdit"
    custom-class="custom-dialog"
    :title="dialogState.dialogTitle"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="472px"
    :append-to-body="true"
  >
    <el-form
      class="custom-form"
      ref="updateFormRef"
      :model="updateForm"
      size="small"
      :rules="rules"
      label-width="100px"
      label-position="right"
    >
      <el-form-item :label="dialogState.labelText" :prop="dialogState.activeKey">
        <template v-if="dialogState.activeKey === 'industry'">
          <el-select v-model="updateForm[dialogState.activeKey]">
            <el-option v-for="(item, key) in INDUSTRY_DICT" :key="key" :value="key" :label="item"></el-option>
          </el-select>
        </template>
        <template v-else-if="dialogState.activeKey === 'scale'">
          <el-select v-model="updateForm[dialogState.activeKey]">
            <el-option
              v-for="item in getListByObj(SCALE_DICT)"
              :label="item.value"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </template>
        <!-- 特殊字符去除 -->
        <el-input
          v-else-if="['companyName', 'shortName'].includes(dialogState.activeKey)"
          v-model.trim="updateForm[dialogState.activeKey]"
          v-withoutSpecial
        ></el-input>
        <el-input v-else v-model.trim="updateForm[dialogState.activeKey]"></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer-btns">
      <el-button
        size="small"
        :disabled="!updateForm[dialogState.activeKey]"
        type="primary"
        @click="handleUpdateConfirm"
      >
        确认
      </el-button>
      <el-button size="small" @click="dialogState.showEdit = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { onActivated, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCompany, useLogo } from '@/hooks/company'
import { timeFormat } from '@/common/utils/format'
import { useFileUpload } from '@/hooks/upload'
import { updateCompanyInfo } from '@/apis'
import { ElMessage } from 'element-plus'
import { mobileAndPhoneValidator, urlValidator } from '@/common/validator'
import { INDUSTRY_DICT, SCALE_DICT } from '@/common/const'
import { getListByObj } from '@/common/utils'
// 子组件
// export const CompanyInfoItem = (props, context) => {
//   return h('div', { ...context.attrs, class: 'company-info__item' }, [
//     h('div', { class: 'item-label' }, context.slots.label()),
//     h('div', { class: 'item-content' }, context.slots.default())
//   ])
// }
const dateFormat = date => {
  return timeFormat(date, 'YYYY年MM月DD日')
}
import CompanyInfoItem from './company-info-item.vue'
export default {
  name: 'companyInfo',
  // eslint-disable-next-line vue/no-unused-components
  components: { CompanyInfoItem },
  setup() {
    const router = useRouter()
    const { state, initCompanyInfo } = useCompany()
    const updateForm = reactive({
      companyName: '', // 企业全称
      shortName: '', // 企业简称
      address: '',
      phone: '',
      domain: '',
      khtLogo: '',
      industry: '',
      scale: ''
    })
    const dialogState = reactive({
      showEdit: false,
      dialogTitle: '',
      labelText: '',
      activeKey: ''
    })
    const updateFormRef = ref(null)
    const logo = useLogo()
    const editKeys = {
      companyName: '企业全称',
      shortName: '企业简称',
      address: '企业地址',
      phone: '联系电话',
      domain: '企业域名',
      industry: '行业类型',
      scale: '人员规模'
    }
    // const initInvoiceList = async () => {
    //   try {
    //     const result = await getInvoiceList({ pageNo: 0, pageSize: 100 })
    //     console.log('ssss', result)
    //   } catch (error) {
    //     console.log('initInvoice error', error)
    //   }
    // }
    // initInvoiceList()
    const paramsKey = {
      companyName: 'fullName',
      shortName: 'name',
      address: 'address',
      phone: 'phone',
      domain: 'url',
      industry: 'type',
      scale: 'staffSize'
    }
    const rules = {
      address: [
        { min: 3, message: '最少输入3个字', trigger: ['blur'] },
        { max: 60, message: '最多输入60个字', trigger: ['blur', 'change'] }
      ],
      shortName: [{ max: 20, message: '最长20个字', trigger: ['blur', 'change'] }],
      companyName: [{ max: 50, message: '最长50个字', trigger: ['blur', 'change'] }],
      domain: [{ validator: urlValidator, message: '域名格式错误', trigger: ['blur', 'change'] }],
      phone: [{ validator: mobileAndPhoneValidator, message: '电话号码格式不正确', trigger: ['blur', 'change'] }]
    }
    // 执行请求
    const handleUpdateInfoRequest = async params => {
      try {
        const result = await updateCompanyInfo(state.id, params)
        if (result) {
          initCompanyInfo()
        }
        ElMessage.success('修改成功')
        dialogState.showEdit = false
      } catch (error) {
        console.log('handleUpdateInfoRequest error', error)
        ElMessage.error('修改失败')
      }
    }
    // 数据处理
    const handleUpdateKey = key => {
      dialogState.dialogTitle = editKeys[key] // 设置dialog 标题
      dialogState.labelText = editKeys[key] // 设置表单label
      updateForm[key] = state[key] // 设置初始值
      dialogState.activeKey = key
    }
    // 处理dialog 确认事件
    const handleUpdateConfirm = () => {
      updateFormRef.value.validate(async valid => {
        if (valid) {
          const params = {
            [paramsKey[dialogState.activeKey]]: updateForm[dialogState.activeKey]
          }
          await handleUpdateInfoRequest(params)
        }
      })
    }
    // 编辑得点击事件
    const onEditClick = key => {
      handleUpdateKey(key)
      dialogState.showEdit = true
      // 清空验证信息
      updateFormRef.value && updateFormRef.value.clearValidate()
    }
    const onAuthClick = () => {
      router.push({ name: 'CompanyAuth' })
    }
    const addInvoice = () => {
      router.push({ name: 'AddInvoice', params: { id: state.invoiceInfo?.id || '' } })
    }

    const { settings, beforeUpload, httpRequest } = useFileUpload(
      file => {
        updateForm.khtLogo = file.id
        handleUpdateInfoRequest({ khtLogo: updateForm.khtLogo })
      },
      { accept: '.png,.jpg', size: '1MB', width: 702, height: 180 }
    )
    onActivated(() => {
      initCompanyInfo()
    })
    return {
      logo,
      onAuthClick,
      addInvoice,
      updateForm,
      onEditClick,
      state,
      dateFormat,
      beforeUpload,
      httpRequest,
      settings,
      dialogState,
      updateFormRef,
      rules,
      handleUpdateConfirm,
      INDUSTRY_DICT,
      SCALE_DICT,
      getListByObj
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
<style lang="scss">
.company-popper {
  .tip {
    .title {
      font-size: 14px;
      color: $text-regular;
    }
    ul {
      padding: 0 0 0 20px;
      margin: 5px 0 0;
      li {
        padding-left: 0;
        font-size: 12px;
        color: $text-secondary;
      }
    }
  }
}
</style>
